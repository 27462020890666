<template>
    <div class="container mt-4">
        <h2>{{ selectedEventName }}</h2>
        <div class="mb-3">
            <label for="barcodeInput" class="form-label">Scan Barcode</label>
            <input ref="barcodeInput" v-model="barcode" @keyup.enter="submitBarcode" type="text" class="form-control"
                id="barcodeInput" placeholder="Pastikan cursor blink disini" />
        </div>
        <div v-if="message" :class="messageClass">{{ message }}</div>
        <div v-if="santri" class="row">
            <div class="col-sm-6">
                <!-- <h3>Detail Santri</h3> -->
                <img :src="image" @error="$event.target.src = err_image" alt="Foto Santri" class="mb-3 img-santri" />

            </div>
            <div class="col-sm-6">
                <p><strong>Nama:</strong> {{ santri.nama }}</p>
                <p><strong>NIS:</strong> {{ santri.nis }}</p>

            </div>
        </div>
    </div>


    <audio ref="player" src="/sound/success.mp3"></audio>
    <audio ref="player2" src="/sound/fail.mp3"></audio>

</template>

<script>
import axios from 'axios';

export default {

    data() {
        return {
            barcode: '',
            message: '',
            messageClass: '',
            santri: null,

            selectedEventId: this.$route.params.idevent,
            selectedEventName: this.$route.query.nama,

            baseurl: axios.defaults.baseURL,
            image: "",
            timewaktu: new Date().getTime(),
            err_image: axios.defaults.baseURL + "../upload/default.jpg",
        };
    },
    beforeUnmount() {
        if (this.focusInterval) {
            clearInterval(this.focusInterval);
        }
    },
    mounted() {
        window.scrollTo({ top: 0, behavior: 'smooth' });

        this.focusBarcodeInput();
        this.preventBackButton();

        this.setupFocusInterval();
    },
    methods: {
        focusBarcodeInput() {
            this.$refs.barcodeInput.focus();
        },
        beforeDestroy() {
            // Remove event listener to prevent memory leaks
            window.removeEventListener('popstate', this.onPopState);
        },
        setupFocusInterval() {
            this.focusInterval = setInterval(() => {
                if (document.activeElement !== this.$refs.barcodeInput) {
                    this.focusBarcodeInput();
                }
            }, 3000);
        },
        async submitBarcode() {
            try {
                const response = await axios.post('api/absensi/check', {
                    barcode: this.barcode,
                    idevent: this.selectedEventId,
                });

                if (response.data.status === 'success') {
                    this.santri = response.data.santri;
                    this.image =
                        this.baseurl +
                        "../upload/santri/" +
                        this.santri.id +
                        ".jpg?dump=" +
                        this.timewaktu;
                    this.message = response.data.message;
                    this.messageClass = 'alert alert-success';
                    this.$refs.player.play();

                } else {
                    this.santri = null;
                    this.message = response.data.message;
                    this.messageClass = 'alert alert-danger';
                    this.$refs.player2.play();
                }
            } catch (error) {
                console.error('Error:', error);
                this.message = 'Terjadi kesalahan saat memproses absensi.';
                this.messageClass = 'alert alert-danger';
                this.$refs.player2.play();
            }

            this.barcode = '';
            this.focusBarcodeInput();
        },
        preventBackButton() {
            // Listener untuk popstate event
            this.onPopState = (event) => {
                // Munculkan peringatan
                alert('Tombol "Back" tidak diperbolehkan.');
                // Kembalikan ke history state sebelum pop
                history.pushState(null, null, location.href);
            };

            window.addEventListener('popstate', this.onPopState);

            // Push initial state to prevent back button
            history.pushState(null, null, location.href);
        }
    },
};
</script>

<style scoped>
.container {
    max-width: 600px;
}

.img-santri {
    width: 100% !important;
    height: auto;
    object-fit: cover;
    object-position: top;
    height: 250px;
}
</style>