<template>
    <div class="container mt-5">
        <div class="row">
            <!-- <div class="col-md-3">
                <div class="list-group">
                    <a href="#" class="list-group-item list-group-item-action active" aria-current="true">
                        24 Jam Terakhir
                    </a>
                    <a href="#" class="list-group-item list-group-item-action">Shalat Subuh</a>
                    <a href="#" class="list-group-item list-group-item-action">Shalat Dhuhur</a>
                    <a href="#" class="list-group-item list-group-item-action">Shalat Ashar</a>
                </div>
            </div> -->
            <div class="col-md-12">
                <h2>Ringkasan Event</h2>
                <!-- <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Proyek A</h5>
                                <p class="card-text">Deskripsi singkat proyek A.</p>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 70%;" aria-valuenow="70"
                                        aria-valuemin="0" aria-valuemax="100">70%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Proyek B</h5>
                                <p class="card-text">Deskripsi singkat proyek B.</p>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 50%;" aria-valuenow="50"
                                        aria-valuemin="0" aria-valuemax="100">50%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="card-title">Proyek C</h5>
                                <p class="card-text">Deskripsi singkat proyek C.</p>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 30%;" aria-valuenow="30"
                                        aria-valuemin="0" aria-valuemax="100">30%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <h2 class="mt-5">Absensi 24 Jam Terakhir</h2>
                <ul class="list-group">
                    <li class="list-group-item">Shalat Subuh - 200 santri</li>
                    <li class="list-group-item">Shalat Dhuhur - 200 santri</li>
                    <li class="list-group-item">Shalat Ashar - 200 santri</li>
                </ul>
                <h2 class="mt-5">Event Khusus Mendatang</h2>
                <ul class="list-group">
                    <li class="list-group-item">Seminar - Schedule: 2024-09-01</li>
                    <li class="list-group-item">Workshop - Schedule: 2024-09-03</li>
                    <li class="list-group-item">Tryout - Schedule: 2024-09-05</li>
                </ul>
           
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'BerandaUmum',
    data() {
        return {
            deferredPrompt: null
        }
    },
    mounted() {
        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            this.deferredPrompt = event;

        });
    },
    methods: {
        showInstallPrompt() {
            if (!this.deferredPrompt) {
                return;
            }
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome
                    === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                this.deferredPrompt = null;
            });
        }
    }
};
</script>

<style></style>