import { createStore } from 'vuex'

export const store = createStore({
    state(){
        return{
            penggunae:{}
        }
    },
    getters:{
        //untuk ambil lansunk dari state
        getPengguna(state){
            return state.penggunae;
        }
    },
    mutations:{
        //untuk ngeset data ke state
        setPengguna(state, datae){
            state.penggunae=datae;
        }
    },
    actions:{
        //agar lebih mudah dipanggil di components dgn sistem asyncronus shingga tidak menjadikan menunggu proses yg lain
        acPengguna(context, datae){
            context.commit('setPengguna', datae);//memanggil fungsi di mutations
        }
    }
})