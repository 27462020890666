<template>
  <div class="sidebarx">
    <div class="sidebar-brand">
      <div class="brand-flex">
        <img src="/img/logo.png" width="30" alt="">
        <span>
          PSD Team
          <div class="ms-4" style="font-size: 0.6em;">Super Master</div>
        </span>
        <!-- <img src="/img/logo.png" width="100" alt=""> -->

        <!-- <div class="brand-icons">
          <span class="icon-menu las la-bell" data-bs-toggle="dropdown" aria-expanded="false"
            id="iconMenuNotifikasi"></span>
          <ul class="dropdown-menu list-icon-menu" aria-labelledby="iconMenuNotifikasi">
            <li><a class="dropdown-item" href="#">Atur Pengingat</a></li>
            <li><a class="dropdown-item" href="#">Notifikasi Pengumuman</a></li>
            <li><a class="dropdown-item" href="#">Bantuan Tim</a></li>
          </ul>
          <span class="icon-menu las la-user-circle" data-bs-toggle="dropdown" aria-expanded="false"
            id="iconMenuProfil"></span>
          <ul class="dropdown-menu list-icon-menu" aria-labelledby="iconMenuProfil">
            <li><a class="dropdown-item" href="#">Jurnalku</a></li>
            <li><a class="dropdown-item" href="#">Keamanan</a></li>
            <li><a class="dropdown-item" href="" @click="logOut">Logout</a></li>
          </ul>
        </div> -->
      </div>
    </div>

    <div class="sidebar-main">
      <div class="sidebar-user">
        <div class="img text-center">
          <!-- <img :src="mainurl_file+'/upload/profil/'+getPengguna.iduser+'.jpg'" alt=""> -->
          <img src="/img/logo2.png" alt="">
        </div>
        <div class="mt-2">
          <h3>{{ getPengguna.namalengkap }}</h3>
          <small v-if="getPengguna.levelx == '1'" class="badge bg-success">Admin<br></small>
        </div>
      </div>

      <div class="sidebar-menu">
        <div class="menu-head">
          <span>Dashboard</span>
        </div>
        <ul class="menu-app">
          <li :class="[{ 'active': $route.name === 'Beranda' }]">
            <router-link to="/">
              <span class="las la-tachometer-alt"></span>
              Beranda
            </router-link>
          </li>
        </ul>

        <div class="menu-head">
          <span>Task Management</span>
        </div>
        <ul class="menu-app">
          <li class="dropdown-btn">
            <a href="#submenu1" aria-expanded="false" data-bs-toggle="collapse" @click="closeAll">
              <span class="las la-user-clock"></span>
              Event Absensi
              <small class="las la-angle-down ms-auto"></small>
            </a>
          </li>
          <ul id="submenu1" class="collapse">
            <li :class="[{ 'active': $route.name === 'QrKartu' }]">
              <router-link to="/absensi/rutin">Rutin</router-link>
            </li>
            <li :class="[{ 'active': $route.name === 'QrListKartu' }]">
              <router-link to="/absensi/khusus">Event Khusus</router-link>
            </li>
          </ul>

        
          <!-- <li :class="[{ 'active': $route.name === 'Milestones' }]">
            <router-link to="/milestones">
              <span class="las la-user-clock"></span>
              Event Rutin
            </router-link>
          </li> -->
          <li :class="[{ 'active': $route.name === 'Riwayat' }]">
            <router-link to="/riwayat">
              <span class="las la-history"></span>
              Riwayat
            </router-link>
          </li>

        </ul>




        <div class="menu-head">
          <span>Konfigurasi</span>
        </div>
        <ul class="menu-app">
          <li :class="[{ 'active': $route.name === 'pass' }]">
            <router-link to="/pass">
              <span class="las la-tools"></span>
              Ganti Kata Sandi
            </router-link>
          </li>
        </ul>

        <ul class="menu-app">
          <li @click.prevent="logOut">
            <a>
              <span class="las la-sign-out-alt"></span>
              LogOut
            </a>
          </li>
        </ul>

      </div>
    </div>
    <br><br>
    <br><br>
    <br><br>
    <br><br>
    <br><br>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
export default {
  name: "Sidebar",
  data() {
    return {
      mainurl_file: axios.defaults.baseURL,
      tipe: 'Light Mode',
    };
  },
  methods: {

    logOut() {
      localStorage.removeItem("token_app");
      this.$store.dispatch("acPengguna", {}); //membersihkan data pengguna di state
      this.$router.push("/login");
    },
    closeAll() {
      //   $(".collapse").collapse({ toggle: false }).collapse("hide");
    },
  },
  computed: {
    ...mapGetters(["getPengguna"]),
  },
};


</script>

<style></style>