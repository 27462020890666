<template>
  <div class="login-page">
      <div class="container d-flex align-items-center">
          <div class="form-holder has-shadow">
              <div class="row">
                  <!-- Logo & Information Panel-->
                  <div class="col-lg-6">
                      <div class="info d-flex align-items-center" style="background-color: green !important">
                          <div class="content">
                              <div class="logo">
                                  <img src="/assets/images/logo.png" style="width: 90px" class="rounded mb-3" />
                                  <h2>Pesantren Smart Digital</h2>
                              </div>
                              <p>Absensi Santri.</p>
                          </div>
                      </div>
                  </div>
                  <!-- Form Panel    -->
                  <div class="col-lg-6">
                      <div class="form d-flex align-items-center">
                          <div class="content">
                              <form method="get" class="form-validate mb-4">
                                  <div class="form-group col-sm-12">
                                      <input id="login-kode" type="text" name="loginKode" required
                                          data-msg="Masukan Kode Yayasan!" class="input-material" v-model="kode"
                                          autocomplete="off" />
                                      <label for="login-kode" class="label-material">Kode Yayasan</label>
                                  </div>
                                  <div class="form-group col-sm-12">
                                      <input id="login-username" type="text" name="loginUsername" required
                                          data-msg="Masukan ID Layanan yang didaftarkan!" class="input-material"
                                          v-model="user" autocomplete="off" />
                                      <label for="login-username" class="label-material">ID Alat</label>
                                  </div>
                                  <div class="form-group col-sm-12">
                                      <input id="login-password" type="password" name="loginPassword" required
                                          data-msg="Masukan kata sandi!" class="input-material" v-model="pass"
                                          autocomplete="off" />
                                      <label for="login-password" class="label-material">Kata Sandi</label>
                                  </div>
                                  <button type="submit" @click.prevent="prosesLogin" class="btn btn-danger">
                                      <i class="las la-power-off"></i> Login
                                  </button>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="copyrights text-center">
          <p>
              <a href="https://digitekperkasa.com" class="external text-warning"><strong>2024 &copy; PT Digitek
                      Perkasa</strong></a>
          </p>
      </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Login",
  data() {
      return {
          kode: "",
          user: "",
          pass: "",
      };
  },
  methods: {
      prosesLogin() {
          if (!this.kode) {
              swal("isikan kode yayasan terlebih dahulu!");
              return;
          }
          if (!this.user) {
              swal("isikan ID Layanan terlebih dahulu!");
              return;
          }
          if (!this.pass) {
              swal("isikan kata sandi terlebih dahulu!");
              return;
          }
          // this.$router.push('/');

          //ambil data v-model yg ada di template
          const datax = {
              kode: this.kode,
              user: this.user,
              pass: this.pass,
          };
          axios
              .put("admin/login", datax)
              .then((res) => {
                  // console.log(res.data);
                  if (res.data.status == "sukses") {
                      localStorage.setItem("token_app", res.data.data.token_app);
                      this.$store.dispatch("acPengguna", res.data.data); //mengirim ke state agar data bisa ditampilkan di navbar atau sidebar
                      axios.defaults.headers.common["Authorization"] =
                          "Bearer " + localStorage.getItem("token_app"); //update bearer

                      this.$router.push("/");
                  } else {
                      swal("Gagal", res.data.pesan, "error");
                  }
              })
              .catch((err) => {
                  console.log(err);
              });
      },
  },

};
</script>

<style>
/* ==========================================================
*     LOGIN PAGE
* ==========================================================
*/

input.input-material {
  width: 100%;
  border: none;
  border-bottom: 1px solid #444951;
  padding: 10px 0;
  background: none;
  color: #fcf9f9;
  outline: none;
}

input.input-material:focus {
  border-color: #0f52ba !important;
}

input.input-material~label {
  color: #aaa;
  position: absolute;
  top: 14px;
  left: 0;
  cursor: text;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  font-weight: 300;
}

input.input-material:valid+label,
input.input-material:focus+label {
  font-size: 0.85rem !important;
  top: -10px;
  color: #0f52ba;
}

/* input.input-material:active + label {
font-size: 0.85rem!important;
top: -10px;
color: #0F52BA;
}  */

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
} */

.login-page {
  position: relative;
}

.login-page::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  /* background-image: url('/img/bg.jpeg'); */
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
}

.login-page .container {
  min-height: 100vh;
  z-index: 1;
  padding: 20px;
}

.login-page .form-holder {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 50px;
}

.login-page .form-holder .info,
.login-page .form-holder .form {
  min-height: 70vh;
  padding: 40px;
  height: 100%;
}

.login-page .form-holder div[class*="col-"] {
  padding: 0;
}

.login-page .form-holder .info {
  background: rgba(219, 101, 116, 0.95);
  color: #fff;
}

.login-page .form-holder .info h1 {
  font-size: 2.5em;
  font-weight: 700;
}

.login-page .form-holder .info p {
  font-weight: 300;
}

.login-page .form-holder .form {
  background: #191c24;
}

.login-page .form-holder .form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.login-page .form-holder .form .content {
  width: 100%;
}

.login-page .form-holder .form form {
  width: 100%;
  max-width: 400px;
}

.login-page .form-holder .form #login,
.login-page .form-holder .form #register {
  margin-bottom: 20px;
  cursor: pointer;
  color: #fff;
}

.login-page .form-holder .form a.forgot-pass,
.login-page .form-holder .form a.signup {
  font-size: 0.9rem;
  color: #0f52ba;
}

.login-page .form-holder .form small {
  color: #aaa;
}

.login-page .form-holder .form .terms-conditions label {
  cursor: pointer;
  color: #aaa;
  font-size: 0.9rem;
}

.login-page .copyrights {
  width: 100%;
  z-index: 9999;
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
}

.external {
  -webkit-text-stroke-width: 0.4px;
  -webkit-text-stroke-color: gray;
}

@media (max-width: 991px) {

  .login-page .form-holder .info,
  .login-page .form-holder .form {
      min-height: auto;
      padding: 40px;
      height: 100%;
  }
}
</style>
