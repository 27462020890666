<template>
    <div class="container mt-4">
      <h2>Pilih Kategori Event Absensi</h2>
      <div class="mb-3">
        <label for="eventSelector" class="form-label">Kategori Event</label>
        <select v-model="selectedEvent" @change="onEventChange" class="form-select" id="eventSelector">
          <option v-for="event in eventConfigurations" :key="event.idevent" :value="event.idevent">
            {{ event.nama_event }} - {{ event.rutin ? 'Rutin' : 'Tidak Rutin' }}
          </option>
        </select>
      </div>
      <div v-if="selectedEventDetails">
        <h3>Detail Event Terpilih</h3>
        <p><strong>Nama Event:</strong> {{ selectedEventDetails.nama_event }}</p>
        <p><strong>Tipe Event:</strong> {{ selectedEventDetails.rutin ? 'Rutin' : 'Tidak Rutin' }}</p>

        <div class="mt-4">
          <router-link class="btn btn-block btn-success" :to="'/absensi/proses/'+selectedEventDetails.idevent+'?nama='+selectedEventDetails.nama_event"><i class="las la-play-circle"></i> Mulai Proses Absen</router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name:"RutinAbsen",
    data() {
      return {
        eventConfigurations: [],
        selectedEvent: null,
        selectedEventDetails: null,
      };
    },
    mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });

      this.getData();
    },
    methods: {
      async getData() {
        try {
          const response = await axios.get('api/event-configurations');
          this.eventConfigurations = response.data;
        } catch (error) {
          console.error('Error fetching event configurations:', error);
        }
      },
      onEventChange() {
        this.selectedEventDetails = this.eventConfigurations.find(event => event.idevent === this.selectedEvent);
      }
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
  }
  </style>
  