<template>
   
        <BerandaUmum />    

</template>

<script>
import { mapGetters } from 'vuex'
import BerandaUmum from '../components/umum/Beranda.vue'
export default {
    name:'Beranda',
    components:{
        BerandaUmum, 
    },
    computed:{
        ...mapGetters(['getPengguna'])
    }
}
</script>

<style>

</style>