import { createRouter, createWebHistory } from "vue-router";

import Login from "./layouts/Login.vue";
import Utama from "./layouts/Utama.vue";
import Beranda from "./menu/Beranda.vue";

import RutinAbsen from "./menu/absensi/RutinAbsen.vue";
import ProsesAbsen from "./menu/absensi/ProsesAbsen.vue";

import Pass from "./menu/pengaturan/PassWord.vue";


const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  {
    path: "/",
    name: "Utama",
    component: Utama,
    children: [
      {
        path: "",
        name: "Beranda",
        component: Beranda,
      },
      {
        path: "absensi/rutin",
        name: "RutinAbsen",
        component: RutinAbsen,
      },
      {
        path: "absensi/proses/:idevent",
        name: "ProsesAbsen",
        component: ProsesAbsen,
      },
      

      {
        path: 'pass',
        name: "Pass",
        component: Pass,
      },

    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

//sadap router
router.beforeEach((to, from, next) => {
  // console.log(from, to); //to, from untuk liat path, next
  // next();

  //cek udah login apa belum
  var udahLogin = false;
  if (localStorage.getItem("token_app") == null) {
    udahLogin = false;
  } else {
    udahLogin = true;
  }

  //cek apakah tujuan selain lokasi login dan kondisi belum login
  if (to.name !== "Login" && !udahLogin) {
    next({ name: "Login" }); //=> maka dilempar kembali ke halaman login
  } else if (to.name === "Login" && udahLogin) {
    //jika posisi udah login gag boleh menuju halaman login lagi
    next({ name: "Beranda" });
  } else {
    if (to.meta.admYayasan && localStorage.getItem("levelx") != "0") {
      next({ name: "Beranda" });
      return;
    }
    if (to.meta.admUnitP && localStorage.getItem("levelx") != "1") {
      next({ name: "Beranda" });
      return;
    }

    next(); //-> tapi klo udah login ke beranda baru bisa keaman aja
  }
});

export default router;
