<template>
  <input type="checkbox" name="" id="sidebar-toggle">

  <!-- Sidebar -->
  <Sidebar />
  <!-- End of Sidebar -->

  <!-- <img src="/img/2.jpg" style="position:fixed;top:0;left:0;z-index:90;width:300px;" /> -->

  <!-- Main Content -->
  <div class="main-content">

    <!-- Topbar -->
    <Navbar />
    <!-- End of Topbar -->

    <!-- Begin Page Content -->
    <main>

      <!-- lokasi kontenx disini -->
      <router-view />

    </main>
    <!-- /.container-fluid -->

  </div>
  <!-- End of Main Content -->

  <!-- Footer -->
  <footer class="pt-4 py-4">
    <div class="container my-auto">
      <div class="copyright text-center my-auto">
        <span>Copyright &copy;2024 <a href="https://digitekperkasa.com" target="_blank" style="text-decoration: none;">PT. Digitek Perkasa</a> </span>
      </div>
    </div>
  </footer>
  <!-- End of Footer -->

  <label for="sidebar-toggle" class="body-label"></label>

</template>

<script>
import axios from "axios";
import Sidebar from "../components/Sidebar.vue";
import Navbar from "../components/Navbar.vue";
export default {
  name: "Utama",
  components: {
    Sidebar,
    Navbar,
  },

  created() {
    //update vuex tiap refresh utk verifikasi pengguna
    axios
      .get("api/pengguna")
      .then((res) => {
        // console.log("beranda bro",res.data);
        localStorage.setItem("token_app", res.data.datae.token_app);
        localStorage.setItem("levelx", res.data.datae.levelx);
        this.$store.dispatch("acPengguna", res.data.datae); //mengirim ke state agar data bisa ditampilkan di navbar atau sidebar
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + localStorage.getItem("token_app"); //update bearer

     
      })
      .catch((err) => {
        // console.log("err: ", err);
        localStorage.removeItem("token_app");
        this.$store.dispatch("acPengguna", {});
        this.$router.push("/login");
      });
  },
  mounted() {},
};
</script>

<style>
</style>