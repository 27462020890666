import { createApp } from "vue";
import App from "./App.vue";
// import "./index.css";
import printJS from 'print-js';
import swal from "sweetalert";
import "./axios";
import { store } from "./store";
import router from "./router";
import VueExcelXlsx from "vue-excel-xlsx";
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import './depedencies/vue-select.css';
import './registerServiceWorker';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueExcelXlsx);
app.component("v-select", VueSelect);
app.mount("#app");

//global variable untuk url
app.config.globalProperties.$urlx = {
  // wapdf:"http://192.168.1.98/pesantrensmartdigital/api-pro/gerbangtool/",
  // storage:"http://192.168.1.98/pesantrensmartdigital/api-pro/upload/",
  wapdf: "https://gerbang.svr-psd.my.id/",
  storage: "https://storage.svr-psd.my.id/",
};

//filter untuk format data dengan javascript
app.config.globalProperties.$filters = {
  angka(value) {
    return new Number(value).toLocaleString("id-ID");
  },
  fixedx(value) {
    return new Number(value).toFixed(0);
  },

  tglIndo(value) {
    var parts = value.split("-");
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    var tgle = new Date(parts[0], parts[1] - 1, parts[2]);
    var bulan = tgle.getMonth();
    var tgl = tgle.getDate();
    var tahun = tgle.getFullYear();
    var hari = tgle.getDay();

    switch (hari) {
      case 0:
        hari = "Ahad";
        break;
      case 1:
        hari = "Senin";
        break;
      case 2:
        hari = "Selasa";
        break;
      case 3:
        hari = "Rabu";
        break;
      case 4:
        hari = "Kamis";
        break;
      case 5:
        hari = "Jum'at";
        break;
      case 6:
        hari = "Sabtu";
        break;
    }
    switch (bulan) {
      case 0:
        bulan = "Januari";
        break;
      case 1:
        bulan = "Februari";
        break;
      case 2:
        bulan = "Maret";
        break;
      case 3:
        bulan = "April";
        break;
      case 4:
        bulan = "Mei";
        break;
      case 5:
        bulan = "Juni";
        break;
      case 6:
        bulan = "Juli";
        break;
      case 7:
        bulan = "Agustus";
        break;
      case 8:
        bulan = "September";
        break;
      case 9:
        bulan = "Oktober";
        break;
      case 10:
        bulan = "November";
        break;
      case 11:
        bulan = "Desember";
        break;
    }
    return hari + ", " + tgl + " " + bulan + " " + tahun;
  },
  tglIndox(value) {
    var parts = value.split("-");
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    var tgle = new Date(parts[0], parts[1] - 1, parts[2]);
    var bulan = tgle.getMonth();
    var tgl = tgle.getDate();
    var tahun = tgle.getFullYear();

    switch (bulan) {
      case 0:
        bulan = "Januari";
        break;
      case 1:
        bulan = "Februari";
        break;
      case 2:
        bulan = "Maret";
        break;
      case 3:
        bulan = "April";
        break;
      case 4:
        bulan = "Mei";
        break;
      case 5:
        bulan = "Juni";
        break;
      case 6:
        bulan = "Juli";
        break;
      case 7:
        bulan = "Agustus";
        break;
      case 8:
        bulan = "September";
        break;
      case 9:
        bulan = "Oktober";
        break;
      case 10:
        bulan = "November";
        break;
      case 11:
        bulan = "Desember";
        break;
    }
    return tgl + " " + bulan + " " + tahun;
  },
  hari(value) {
    var hari = value;
    switch (hari) {
      case "0":
        hari = "Ahad";
        break;
      case "1":
        hari = "Senin";
        break;
      case "2":
        hari = "Selasa";
        break;
      case "3":
        hari = "Rabu";
        break;
      case "4":
        hari = "Kamis";
        break;
      case "5":
        hari = "Jum'at";
        break;
      case "6":
        hari = "Sabtu";
        break;
    }

    return hari;
  },
  hariInt(value) {
    var hari = value;
    switch (hari) {
      case 0:
        hari = "Ahad";
        break;
      case 1:
        hari = "Senin";
        break;
      case 2:
        hari = "Selasa";
        break;
      case 3:
        hari = "Rabu";
        break;
      case 4:
        hari = "Kamis";
        break;
      case 5:
        hari = "Jum'at";
        break;
      case 6:
        hari = "Sabtu";
        break;
    }

    return hari;
  },
  bulanInt(bulan) {
    switch (bulan - 1) {
      case 0:
        bulan = "Januari";
        break;
      case 1:
        bulan = "Februari";
        break;
      case 2:
        bulan = "Maret";
        break;
      case 3:
        bulan = "April";
        break;
      case 4:
        bulan = "Mei";
        break;
      case 5:
        bulan = "Juni";
        break;
      case 6:
        bulan = "Juli";
        break;
      case 7:
        bulan = "Agustus";
        break;
      case 8:
        bulan = "September";
        break;
      case 9:
        bulan = "Oktober";
        break;
      case 10:
        bulan = "November";
        break;
      case 11:
        bulan = "Desember";
        break;
    }
    return bulan;
  },
  bulanIndo(value) {
    var parts = value.split("-");
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    var tgle = new Date(parts[0], parts[1] - 1, parts[2]);
    var bulan = tgle.getMonth();

    switch (bulan) {
      case 0:
        bulan = "Januari";
        break;
      case 1:
        bulan = "Februari";
        break;
      case 2:
        bulan = "Maret";
        break;
      case 3:
        bulan = "April";
        break;
      case 4:
        bulan = "Mei";
        break;
      case 5:
        bulan = "Juni";
        break;
      case 6:
        bulan = "Juli";
        break;
      case 7:
        bulan = "Agustus";
        break;
      case 8:
        bulan = "September";
        break;
      case 9:
        bulan = "Oktober";
        break;
      case 10:
        bulan = "November";
        break;
      case 11:
        bulan = "Desember";
        break;
    }
    return bulan;
  },
  bulanTahunIndo(value) {
    var parts = value.split("-");
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    var tgle = new Date(parts[0], parts[1] - 1, parts[2]);
    var bulan = tgle.getMonth();
    var tahun = tgle.getFullYear();

    switch (bulan) {
      case 0:
        bulan = "Januari";
        break;
      case 1:
        bulan = "Februari";
        break;
      case 2:
        bulan = "Maret";
        break;
      case 3:
        bulan = "April";
        break;
      case 4:
        bulan = "Mei";
        break;
      case 5:
        bulan = "Juni";
        break;
      case 6:
        bulan = "Juli";
        break;
      case 7:
        bulan = "Agustus";
        break;
      case 8:
        bulan = "September";
        break;
      case 9:
        bulan = "Oktober";
        break;
      case 10:
        bulan = "November";
        break;
      case 11:
        bulan = "Desember";
        break;
    }
    return bulan + " " + tahun;
  },

  tahun(value) {
    var parts = value.split("-");
    // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
    // January - 0, February - 1, etc.
    var tgle = new Date(parts[0], parts[1] - 1, parts[2]);
    var tahun = tgle.getFullYear();

    return tahun;
  },

  resizeImage(base64Str) {
    var img = new Image();
    img.src = base64Str;
    var canvas = document.createElement("canvas");
    var MAX_WIDTH = 600;
    var MAX_HEIGHT = 600;
    var width = img.width;
    var height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL("image/jpeg", 0.5);
  },

  terbilang(bilangan) {
    let i, j, kaLimat, subkaLimat, kata1, kata2, kata3;

    bilangan = String(bilangan);
    var angka = new Array(
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0",
      "0"
    );
    var kata = new Array(
      "",
      "Satu",
      "Dua",
      "Tiga",
      "Empat",
      "Lima",
      "Enam",
      "Tujuh",
      "Delapan",
      "Sembilan"
    );
    var tingkat = new Array("", "Ribu", "Juta", "Milyar", "Triliun");

    var panjang_bilangan = bilangan.length;

    /* pengujian panjang bilangan */
    if (panjang_bilangan > 15) {
      kaLimat = "Diluar Batas";
      return kaLimat;
    }

    /* mengambil angka-angka yang ada dalam bilangan, dimasukkan ke dalam array */
    for (i = 1; i <= panjang_bilangan; i++) {
      angka[i] = bilangan.substr(-i, 1);
    }

    i = 1;
    j = 0;
    kaLimat = "";

    /* mulai proses iterasi terhadap array angka */
    while (i <= panjang_bilangan) {
      subkaLimat = "";
      kata1 = "";
      kata2 = "";
      kata3 = "";

      /* untuk Ratusan */
      if (angka[i + 2] != "0") {
        if (angka[i + 2] == "1") {
          kata1 = "Seratus";
        } else {
          kata1 = kata[angka[i + 2]] + " Ratus";
        }
      }

      /* untuk Puluhan atau Belasan */
      if (angka[i + 1] != "0") {
        if (angka[i + 1] == "1") {
          if (angka[i] == "0") {
            kata2 = "Sepuluh";
          } else if (angka[i] == "1") {
            kata2 = "Sebelas";
          } else {
            kata2 = kata[angka[i]] + " Belas";
          }
        } else {
          kata2 = kata[angka[i + 1]] + " Puluh";
        }
      }

      /* untuk Satuan */
      if (angka[i] != "0") {
        if (angka[i + 1] != "1") {
          kata3 = kata[angka[i]];
        }
      }

      /* pengujian angka apakah tidak nol semua, lalu ditambahkan tingkat */
      if (angka[i] != "0" || angka[i + 1] != "0" || angka[i + 2] != "0") {
        subkaLimat = kata1 + " " + kata2 + " " + kata3 + " " + tingkat[j] + " ";
      }

      /* gabungkan variabe sub kaLimat (untuk Satu blok 3 angka) ke variabel kaLimat */
      kaLimat = subkaLimat + kaLimat;
      i = i + 3;
      j = j + 1;
    }

    /* mengganti Satu Ribu jadi Seribu jika diperlukan */
    if (angka[5] == "0" && angka[6] == "0") {
      kaLimat = kaLimat.replace("Satu Ribu", "Seribu");
    }

    return kaLimat + "Rupiah";
  },

  /* happy */
  noValidasi(value) {
    let s = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    let r = "";
    let n;

    // Loop until the length of the random string is 16
    while (r.length <= 16) {
      n = Math.floor(Math.random() * 61); // Generate a random number between 0 and 60
      r += s.charAt(n); // Concatenate a character from s at position n to r
    }

    return r; // Update the randomString data property with the generated random string
  },

  limitDecimal(value) {
    // Periksa apakah nilai memiliki desimal
    if (value % 1 !== 0) {
      // Jika memiliki desimal, gunakan fungsi toFixed() untuk membatasi jumlah angka di belakang desimal
      return parseFloat(value).toFixed(3);
    } else {
      // Jika nilai adalah bilangan bulat, kembalikan nilainya tanpa perubahan
      return value;
    }
  },
};
